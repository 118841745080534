import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-makefile"
      }}>{`.source: script =
.source:
    @$(foreach FUNCTION,$(shell source $(script) && declare -F | awk '{print $$3}'), \\
        $(eval $(FUNCTION) := $(shell echo ". $(SCRIPT); $(FUNCTION)")) \\
    )
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      